.landing-page {
  position: relative;
  background-image: url("bgg.jpg"); 
  background-size: cover;
  background-position: center;
  height: 625px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  padding-left: 40px;
  z-index: 1; 
}

.landing-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.overlay-frame {
  position: absolute;
  top: -100px; 
  right: 55px;
  transform: rotate(45deg) scale(0.8); 
  z-index: 0; 
  padding: 5px;
  border-radius: 60px;
  background: #fff; 
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  overflow: hidden; 
}

.overlay-image {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  border-radius: 30px;
  transform: rotate(-45deg) scale(1.4); 
  box-shadow: 0px 4px 15px rgba(32, 32, 32, 0.3);
}

.navbar {
  position: relative;
  z-index: 2; 
}

.landing-content {
  margin-left: 40px;
  position: relative;
  z-index: 3;
  animation: right 1s ease forwards;
}

.landing-content h1 {
  font-size: 40px;
  margin-bottom: 20px;
  line-height: 1.2;
  font-weight: bold;
}

.landing-content p {
  font-size: 22px;
  margin-bottom: 30px;
  line-height: 1.5;
}

.landing-content button {
  font-weight: bold;
  padding: 15px 25px;
  font-size: 20px;
  background-color: #007bff;
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.landing-content button a {
  text-decoration: none;
}

.landing-content button:hover {
  background-color: #0056b3;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .landing-page {
    height: auto; /* Adjust height to fit content */
    flex-direction: column; /* Stack content vertically */
    padding: 20px;
  }

  .overlay-frame {
    position: static;
    margin: 20px 0;
    transform: rotate(0deg) scale(1); /* Reset rotation for better fit */
  }

  .overlay-image {
    display: none;
  }

  .landing-content {
    margin: 0 auto;
    text-align: center; /* Center align content */
  }

  .landing-content h1 {
    font-size: 36px; /* Slightly smaller for tablets */
  }

  .landing-content p {
    font-size: 20px;
  }
}

/* Responsive for mobile (max-width: 768px) */
@media (max-width: 768px) {
  .landing-page {
    height: auto; /* Adjust height for mobile */
    flex-direction: column;
    padding: 10px;
    text-align: center; /* Center align content */
  }

  .overlay-frame {
    position: static;
    margin: 20px 0;
    transform: rotate(0deg) scale(1); /* Reset rotation */
  }

  .overlay-image {
    display: none;
  }

  .landing-content {
    margin: 15px auto;
    max-width: 90%; /* Fit within screen width */
  }

  .landing-content h1 {
    font-size: 32px; /* Reduce font size for smaller screens */
  }

  .landing-content p {
    font-size: 18px;
  }

  .landing-content button {
    padding: 10px 20px;
    font-size: 16px; /* Adjust button size */
  }
}