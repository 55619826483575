.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 2px;
  background: linear-gradient(to right, #ffffff 20%, #0C051C 60%);
  margin-bottom: -20px;
}

.navbar-logo img {
  height: 125px;
  width: 115px;
  margin-left: 80px;
}

.navbar-links {
  list-style: none;
  display: flex;
  padding: 30px;
}

.navbar-links li {
  margin-top: 10px;
  margin-left: 20px;
  padding: 0 22px;
}

.navbar-links a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
}

.navbar-links .contact-btn {
  margin-top: -10px;
  background: #0683FC;
  color: #fff;
  padding: 20px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px #0683FC, 0 0 20px #0683FC, 0 0 30px #0683FC;
  transition: box-shadow 0.3s ease-in-out;
}

.navbar-links .contact-btn:hover {
  box-shadow: 0 0 20px #0683FC, 0 0 30px #0683FC, 0 0 40px #0683FC;
}

/* Hamburger menu styling */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-right: 20px;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
  transition: 0.3s;
}

/* Responsive for tablets and mobile */
@media (max-width: 768px) {
  .navbar {
    background: linear-gradient(to right, #ffffff 30%, #0C051C 60%);

  }

  .navbar-links {
    margin-top: 0px;
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: -10px;
    right: 0px;
    background: #0C051C;
    width: 100%;
    padding: 20px 10px;
    text-align: center;
    gap: 15px;
  }

  .navbar-links.active {
    display: flex;
  }

  .navbar-links li {
    margin: 10px 0;
  }

  .navbar-logo img {
    margin-top: 2px;
    height: 100px;
    width: 90px;
    margin-left: 10px;
  }

  .hamburger {
    display: flex;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .navbar {
    padding: 10px 20px;
  }

  .navbar-logo img {
    height: 110px;
    width: 100px;
    margin-left: 40px;
  }

  .navbar-links {
    padding: 20px;
  }

  .navbar-links li {
    padding: 0 10px;
  }

  .navbar-links a {
    font-size: 18px; /* Keep font size unchanged */
  }

  .navbar-links .contact-btn {
    padding: 15px 20px;
  }

  .hamburger {
    display: none; /* Keep the full navigation visible on tablets */
  }
}
