.about-me-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  background-color: #fff; 
  gap: 40px; 
}

.about-me-content {
  flex: 1;
  max-width: 600px;
  padding-right: 200px;
}

.about-me-content h2 {
  font-size: 32px;
}

.about-me-content h3 {
  font-size: 38px;
  color: #333;
  margin-top: 20px;
}

.highlight {
  color: #007bff; 
}

.about-me-content p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
}

.about-me-image {
  flex: 1;
  max-width: 300px;
  text-align: center;
  margin-left: -50px;
  margin-right: 150px;
}

.ceo-image {
    width: 150%;
    height: auto;
    border-radius: 50%; /* Make the image circular */
    border: 8px solid #345FBD; /* First layer of border color */
    box-shadow: 0 0 0 10px #0C051C; /* Second layer of border color */
}

/* Media query for max-width 768px */
@media (max-width: 768px) {
  .about-me-container {
    flex-direction: column; /* Stack the content vertically */
    align-items: center; /* Center the content horizontally */
    padding: 20px;
  }

  .about-me-content {
    max-width: 100%;
    padding-right: 0;
    text-align: center; /* Center-align the text */
  }

  .about-me-content h2 {
    font-size: 28px;
  }

  .about-me-content h3 {
    font-size: 32px;
  }

  .about-me-content p {
    font-size: 14px;
    text-align: center; /* Center the paragraph text */
  }

  .about-me-image {
    margin-top: -25px;
    max-width: 80%; /* Image will take less width */
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .ceo-image {
    width: 100%;
    height: auto;
    border-radius: 50%;
    border: 5px solid #345FBD;
    box-shadow: 0 0 0 5px #0C051C;
  }
}

/* Media query for min-width 768px and max-width 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .about-me-container {
    flex-direction: row;
    gap: 70px;
  }

  .about-me-content {
    max-width: 600px;
    padding-right: 20px;
  }

  .about-me-content h2 {
    font-size: 30px;
  }

  .about-me-content h3 {
    font-size: 35px;
  }

  .about-me-content p {
    font-size: 15px;
  }

  .about-me-image {
    margin-right: 80px;
    max-width: 250px;
  }

  .ceo-image {
    width: 120%;
    height: auto;
  }
}

/* Media query for min-width 1024px */
/* @media (min-width: 1024px) {
  .about-me-container {
    flex-direction: row;
    gap: 40px;
  }

  .about-me-content {
    max-width: 600px;
    padding-right: 50px;
  }

  .about-me-content h2 {
    font-size: 32px;
  }

  .about-me-content h3 {
    font-size: 38px;
  }

  .about-me-content p {
    font-size: 16px;
  }

  .about-me-image {
    max-width: 300px;
  }

  .ceo-image {
    width: 150%;
    height: auto;
  }
} */