.solar-header {
  text-align: center;
  margin-bottom: 40px;
  padding: 0px 40px;
}

.post-title {
  margin-top: 100px;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 50px;
}

.post-title span {
  color: #007bff; 
  font-weight: bold;
}

.subheading {
  color: #666;
  font-size: 1rem;
  line-height: 30px;
  margin: 50px auto;
  text-align: left;
  margin-left: 40px;
}

.solar-gallery {
  margin-right: 300px;
  min-width: 1200px;
  padding: 0px 100px;
  margin-left: 85px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
}

.solar-gallery .gallery-image,
.solar-gallery .gallery-image-1 {
  position: relative;
  /* overflow: hidden; */
}

.solar-gallery .gallery-text {
  margin-left: 10px;
  line-height: 2;
  text-align: justify;
  margin-top: -10px;
  color: #666;
}

.solar-gallery .gallery-text .content-heading,
.solar-gallery .gallery-text-1 .content-heading {
  font-size: 1.7rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 8px;
}

.gallery-text .content-text,
.gallery-text-1 .content-text {
  line-height: 2.2;
}

.solar-gallery .gallery-text-1 {
  line-height: 2;
  text-align: justify;
  color: #666;
  margin-left: -80px;
}

.solar-gallery .gallery-image img,
.solar-gallery .gallery-image-1 img {
  width: 600px;
  height: 400px;
  border-radius: 10px;
  display: block;
  margin-left: -50px;
  /* object-fit: cover; */
}

.gallery-image {
  animation: right 2s ease forwards;
}

.gallery-image-1 {
  animation: left 5s ease forwards;
}

.solar-gallery .gallery-image-1 {
  margin-left: 80px;
}

.solar-content {
  padding: 20px 40px;
  text-align: justify;
  justify-content: center;
  font-size: 1rem;
  color: #666;
  line-height: 1.8;
  margin: 0px 50px;
}

.related-articles {
  text-align: center;
  margin: 20px;
}

.related-articles h2 {
  padding: 20px;
  font-size: 30px;
}

.related-articles .capacity {
  margin-bottom: 30px;
  /* text-align: left;
  margin-left: 100px; */
  font-size: 22px;
}

.articles {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three cards per row */
  gap: 30px; /* Increased space between cards */
  margin-top: 20px;
}

.article {
  position: relative;
  overflow: hidden; /* Ensures content stays within the card */
  border: 1px solid #8f8b8b; /* Border around each card */
  border-radius: 12px; /* Slightly larger corner radius */
  text-align: center; /* Align content to center */
  border-inline: 5px solid rgb(11, 2, 88);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
  width: 350px; /* Increased card width */
  height: 350px; /* Increased card height */
  margin: auto; /* Center cards horizontally */
}

.article img {
  height: 100px;
  width: 300px;
}

.article:hover {
  transform: scale(1.1); /* Slightly larger scale on hover */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.article h3 {
  font-size: 22px; /* Larger font size for the title */
  margin: 30px 0; /* Increased spacing around the title */
  color: #000000; /* Title color */
  margin-top: 90px;
}

.article-details {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95); /* Semi-transparent background */
  padding: 10px; /* Increased padding for more content space */
  box-sizing: border-box;
  text-align: left;
  overflow: auto; /* Enable scrolling for large content */
  transition: top 0.3s ease; /* Smooth slide-in effect */
}

.article:hover .article-details {
  top: 0; 
}

.article-details p {
  color: #202020; /* Text color */
  font-size: 11.6px; /* Slightly larger font size for the description */
  line-height: 1.3; /* Better readability with increased line height */
}

/* Mobile: max-width 600px */
@media (max-width: 600px) {
  .solar-page {
    max-width: 600px;
    margin: 0 auto;
    overflow-x: hidden; /* Prevent horizontal scroll */
    padding: 0; /* Remove unwanted padding */
    box-sizing: border-box; /* Ensure padding doesn't affect the overall width */
  }

  .solar-header {
    margin-top: -70px;
    text-align: center;
    padding: 10px;
  }

  .post-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .subheading {
    margin-top: 30px;
    font-size: 0.9rem;
    line-height: 1.8;
    padding: 0 10px;
    margin-left: 5px;
    text-align: center;
  }

  .solar-gallery {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
  }

  .solar-gallery .gallery-image img{
    margin-left: -88px;
    margin-top: -90px;
    width: 390px;
    height: 300px;
  }

  .solar-gallery .gallery-image-1 img{
    margin-left: -168px;
    margin-top: 0px;
    width: 390px;
    height: 300px;
  }

  .solar-gallery .gallery-text,
  .solar-gallery .gallery-text-1 {
    margin-left: -80px;
    font-size: 0.8rem;
    text-align: justify;
  }

  .gallery-text,
  .gallery-text-1 {
    font-size: 0.9rem;
    line-height: 1.6;
    padding: 0 10px;
    text-align: justify;
  }

  .gallery-text .content-text,
  .gallery-text-1 .content-text {
    display: none;
  }

  .solar-content {
    padding: 0 5px;
    font-size: 0.9rem;
    line-height: 1.8;
    text-align: center;
  }

  .related-articles h2 {
    font-size: 1.4rem;
    text-align: center;
  }

  .articles {
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 10px;
  }

  .article {
    width: 90%;
    margin: 0 auto;
  }

  .article img {
    width: 100%;
    height: auto;
  }

  .article h3 {
    font-size: 1rem;
  }

  .article-details {
    font-size: 0.8rem;
  }
}

/* Tablet: max-width 900px */
@media (min-width: 600px) and (max-width: 900px) {
  .solar-header {
    text-align: center;
    padding: 15px;
  }

  .post-title {
    margin-top: 40px;
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .subheading {
    margin-top: 30px;
    font-size: 1rem;
    line-height: 1.6;
    padding: 0 15px;
    text-align: justify;
  }

  .solar-gallery {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px;
  }

  .solar-gallery .gallery-text {
    margin-left: -80px;
  }

  .solar-gallery .gallery-image img{
    margin-left: 100px;
    margin-top: -90px;
    width: 450px;
    height: 300px;
  }

  .solar-gallery .gallery-image-1 img{
    margin-left: -30px;
    width: 500px;
    height: 350px;
  }

  .gallery-text,
  .gallery-text-1 {
    font-size: 1rem;
    line-height: 1.6;
    padding: 0 15px;
    text-align: justify;
  }

  .solar-content {
    padding: 15px;
    font-size: 1rem;
    line-height: 1.6;
    text-align: justify;
  }

  .related-articles h2 {
    font-size: 1.6rem;
    text-align: center;
  }

  .articles {
    grid-template-columns: 1fr;
    gap: 25px;
    padding: 15px;
  }

  .article {
    width: 90%;
    margin: 0 auto;
  }

  .article img {
    width: 100%;
    height: auto;
  }

  .article h3 {
    font-size: 1.1rem;
  }

  .article-details {
    font-size: 0.9rem;
  }
}

/* Desktop: max-width 1200px */
@media (min-width: 900px) and (max-width: 1200px) {
  .solar-header {
    text-align: center;
    padding: 20px;
  }

  .post-title {
    margin-top: 50px;
    font-size: 2rem;
    margin-bottom: 25px;
  }

  .subheading {
    margin-top: 40px;
    margin-left: 10px;
    font-size: 1.1rem;
    line-height: 1.8;
    padding: 0 20px;
    text-align: justify;
  }

  .solar-gallery {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px;
  }

  .solar-gallery .gallery-text {
    margin-left: -80px;
  }

  .solar-gallery .gallery-image img{
    margin-left: 100px;
    margin-top: -90px;
    width: 550px;
    height: 400px;
  }

  .solar-gallery .gallery-image-1 img{
    margin-left: -0px;
    width: 550px;
    height: 400px;
  }

  .gallery-text,
  .gallery-text-1 {
    font-size: 1.1rem;
    line-height: 1.8;
    padding: 0 20px;
    text-align: justify;
  }

  .solar-content {
    padding: 20px;
    font-size: 1.1rem;
    line-height: 1.8;
    text-align: justify;
  }

  .related-articles h2 {
    font-size: 1.8rem;
    text-align: center;
  }

  .articles {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding: 20px;
  }

  .article {
    width: 100%;
  }

  .article img {
    width: 100%;
    height: auto;
  }

  .article h3 {
    font-size: 1.2rem;
  }

  .article-details {
    font-size: 1rem;
  }
}
