.subscription-section {
  padding: 50px 20px;
  background: linear-gradient(#0c051c, #345fbd);
  color: #fff;
  text-align: center;
}

.subscription-section h3 {
  font-size: 35px;
}

.subscription-section .email input {
  margin-left: -20px;
}

.email-holder {
  width: 30%;
  padding: 20px;
  border-radius: 10px;
  border: none;
  margin-top: 20px;
}

.email .subscribe-btn {
  background-color: #000000;
  color: #fff;
  padding: 22px 23px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 30px;
}

.sub-detail {
  margin-top: 40px;
  font-size: 18px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .subscription-section h3 {
    font-size: 30px; /* Adjust heading size for tablets */
  }

  .email-holder {
    width: 50%; /* Increase input width for tablets */
    padding: 14px;
    font-size: 15px;
  }

  .email .subscribe-btn {
    padding: 14px 18px;
    font-size: 15px;
  }

  .sub-detail {
    font-size: 16px; /* Adjust paragraph size for tablets */
  }
}

@media (max-width: 768px) {
  .subscription-section h3 {
    font-size: 25px; /* Adjust heading size for mobile */
  }

  .email-holder {
    width: 80%; /* Increase input width for mobile */
    padding: 12px;
    font-size: 14px;
  }

  .email .subscribe-btn {
    margin-top: 20px;
    padding: 12px 16px;
    font-size: 14px;
  }

  .sub-detail {
    font-size: 14px; /* Adjust paragraph size for mobile */
  }
}
