.about-us {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  background-color: #ffffff;
}

.about-us-content {
  margin-left: 100px;
  padding: 20px;
  flex: 1;
}

.about-us-content h2 {
  font-size: 32px;
  color: #333;
  margin-bottom: 10px;
}

.about-us-content h1 {
  font-size: 48px;
  color: #000;
  margin-bottom: 20px;
}

.highlight {
  color: #0683FC; /* Highlighted company name color */
}

.about-us-content p {
  font-size: 16px;
  color: #555;
  line-height: 1.8;
  margin-bottom: 15px;
}

.about-us-image {
  margin-top: -20px;
  margin-left: -125px;
  flex: 1;
}

.about-us-image img {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 150px;
  border-bottom-right-radius: 150px;
  width: 100%;
  max-width: 900px;
  height: 580px;
  animation: right 1s ease forwards;
}

/* Media query for screens with max-width 768px */
@media (max-width: 768px) {
  .about-us {
    flex-direction: column;
    padding: 20px;
  }

  .about-us-content {
    margin-top: 15px;
    margin-left: 0;
    padding: 10px;
    text-align: center;
  }

  .about-us-content h2 {
    font-size: 24px;
  }

  .about-us-content h1 {
    font-size: 30px;
  }

  .about-us-content p {
    font-size: 14px;
  }

  .about-us-image img {
    display: none;
  }
}

/* Media query for screens with min-width 768px and max-width 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .about-us {
    flex-direction: column;
    padding: 30px;
  }

  .about-us-content {
    margin-left: 0;
    padding: 15px;
    text-align: center;
  }

  .about-us-content h2 {
    font-size: 28px;
  }

  .about-us-content h1 {
    font-size: 38px;
  }

  .about-us-content p {
    font-size: 15px;
  }

  .about-us-image img {
    display: none;
  }
}