.footer {
  background-color: #0C051C;
  color: white;
  padding: 20px 0;
  font-family: 'Arial', sans-serif;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-section {
  flex: 1;
}

.footer-container .site-name {
  margin-left: -70px;
}

.site-name img {
  height: 100px;
  width: 100px;
}

.site-name h4 {
  margin-top: 55px;
  font-size: 25px;
  margin-bottom: 20px;
}

.site-name p {
  margin-bottom: 20px;
}

.social-icons a {
  margin-right: 10px;
  color: white;
  font-size: 20px;
  padding: 13px;
  text-decoration: none;
}

.footer-container .quick-links {
  margin-left: 300px;
}

.quick-links h4,
.contact-us h4 {
  font-size: 20px;
  margin-top: 10px;
}

.quick-links ul {
  list-style: none;
  padding: 0;
}

.quick-links ul li {
  margin-bottom: 10px;
  padding: 3px;
}

.quick-links ul li a {
  font-size: 16px;
  color: white;
  text-decoration: none;
}

.quick-links ul li a:hover {
  text-decoration: underline;
}

.footer-container .contact-us {
  line-height: 1.3;
  margin-left: 300px;
}

.contact-us p {
  padding: 3px;
  margin: 10px 0;
}

.contact-us p i {
  margin-right: 10px;
}

.copyright, .developer {
  color: white;
  font-size: 1rem;
}

.lingo {
  height: auto;
}

/* Responsive Design for max-width: 1024px */
@media (max-width: 1024px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-section {
    margin-bottom: 20px;
  }

  .quick-links {
    margin-top: 20px;
    margin-right: 300px;
  }
  
  .quick-links h4 {
    font-size: 18px;
    white-space: nowrap;
  }

  .contact-us {
    margin-right: 300px;
    white-space: nowrap;
  }

  .site-name {
    margin-right: -75px;
  }

  .site-name img {
    height: 80px;
    width: 80px;
  }

  .site-name h4 {
    font-size: 22px;
  }

  .social-icons a {
    font-size: 18px;
    padding: 10px;
  }

  .quick-links h4,
  .contact-us h4 {
    font-size: 18px;
  }

  .quick-links ul li a,
  .contact-us p {
    font-size: 14px;
  }

  .lingo {
    max-width: 80px;
  }
}

/* Responsive Design for max-width: 768px */
@media (max-width: 768px) {
  .footer-container {
    padding: 0;
  }

  .footer-section {
    margin-bottom: 15px;
    margin-right: -60px;
  }

  .quick-links {
    margin-top: 20px;
    margin-right: 310px;
  }
  
  .quick-links h4 {
    font-size: 18px;
    white-space: nowrap;
  }

  .contact-us {
    margin-right: 310px;
    white-space: nowrap;
  }

  .site-name img {
    height: 70px;
    width: 70px;
  }

  .site-name h4,
  .quick-links h4,
  .contact-us h4 {
    font-size: 20px;
  }

  .social-icons a {
    font-size: 16px;
    padding: 8px;
  }

  .quick-links ul li a,
  .contact-us p {
    font-size: 12px;
  }

  .lingo {
    max-width: 70px;
  }
}