body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.construction-page .subheading {
    padding: 0px 70px;
    text-align: center;
}

.construction-page .sub-heading-2 {
    padding: 0px 70px;
    text-align: center;
    line-height: 2;
    color: #666;
}

.cons-section {
  padding: 50px 10px;
  background: linear-gradient(to right, #0C051C, #345FBD);
  color: #fff;
  text-align: center;
}

.cons-cards {
display: flex;
justify-content: space-between; 
flex-wrap: wrap; 
gap: 20px; 
margin: 0 auto; 
padding: 10px;
}

.cons-card {
background-color: #ffffff;
color: #0c051c;
width: calc(20% - 20px); 
border-radius: 8px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

.cons-card img {
margin-top: 20px;
height: 70px;
width: 70px;
}

.cons-card h3 {
font-size: 20px;
margin-bottom: 10px;
}

.cons-card p {
padding: 0px 10px;
font-size: 14px;
line-height: 1.6; 
}

.con-gallery {
  margin-right: 300px;
  min-width: 1200px;
  padding: 0px 100px;
  margin-left: 85px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
}

.con-gallery .gallery-image-con1,
.con-gallery .gallery-image-con2 {
  position: relative;
  /* overflow: hidden; */
}

.con-gallery .gallery-image-con1 img,
.con-gallery .gallery-image-con2 img {
  width: 600px;
  height: 400px;
  border-radius: 10px;
  display: block;
  margin-left: -50px;
  /* object-fit: cover; */
}

.con-gallery .gallery-image-con2 {
  right: -50px;
}

.gallery-image-con1{
  animation: right 2s ease forwards;
}

.gallery-image-con2 {
  animation: left 2s ease forwards;
}

/* For Mobile: max-width 600px */
@media (max-width: 600px) {
  .construction-page {
    max-width: 600px;
    margin: 0 auto;
    overflow-x: hidden; /* Prevent horizontal scroll */
    padding: 0; /* Remove unwanted padding */
    box-sizing: border-box; /* Ensure padding doesn't affect the overall width */
  }

  .construction-page .post-title {
    margin-top: 60px;
  }

  .construction-page .subheading {
    padding: 0px 20px;
    font-size: 0.9rem;
    line-height: 1.8;
  }

  .construction-page .sub-heading-2 {
    padding: 0px 30px;
    font-size: 0.9rem;
    line-height: 1.8;
  }

  .con-gallery {
    margin-left: -403px;
    grid-template-columns: 1fr; /* Single-column layout */
    gap: 20px; /* Space between the images */
    padding: 0 10px; /* Adjust padding for mobile */
  }

  .con-gallery .gallery-image-con1 img {
    margin-right: -600px;
    width: 380px; 
    height: 280px; 
    margin: 0 auto; 
  }

  .con-gallery .gallery-image-con2 img {
    width: 380px; 
    height: 280px; 
    margin: 10px auto; 
    margin-right: 457px;
  }

  /* Construction Cards in .cons-cards: Stack one below the other */
  .cons-cards {
    flex-direction: column; /* Stack cards vertically */
    gap: 15px; /* Space between cards */
    padding: 0 10px; /* Reduce padding for mobile */
  }

  .cons-card {
    width: 100%; /* Full width for cards */
    margin: 0 auto; /* Center-align cards */
  }
}
