.welcome-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 20px;
  background-color: #f8f9fa;
  text-align: left;
}

.welcome-content {
  margin-left: 100px;
}

.welcome-content h3 {
  margin-top: 30px;
  color: #007bff;
  font-size: 23px;
  margin-bottom: 10px;
}

.welcome-content h2 {
  font-size: 35px;
  margin-bottom: 30px;
  color: #212529;
  font-weight: bold;
}

.welcome-content p {
  line-height: 35px;
  font-size: 18px;
  margin-bottom: 30px;
  color: #495057;
}

.features-list {
  list-style: none;
  padding-left: 0;
  line-height: 25px;
  margin-bottom: 30px;
  font-size: 18px;
  color: #345FBD;
}

.features-list li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.features-list li::before {
  content: "✔️";
  color: #007bff;
  margin-right: 10px;
}

.read-more-btn {
  padding: 15px 30px;
  font-size: 18px;
  background-color: #ffffff;
  border: 2px solid #007bff;
  color: #007bff;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.read-more-btn:hover {
  background-color: #77b6fa;
}

.welcome-image img {
  margin-left: 40px;
  height: 600px;
  width: 800px;
  border-radius: 15px;
  object-fit: cover;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  animation: right 6s ease forwards;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .welcome-section {
    flex-direction: column; /* Stack image and content vertically */
    text-align: center; /* Center-align content */
  }

  .welcome-content {
    margin: 20px auto; /* Center content */
    text-align: center; /* Center-align text */
  }

  .welcome-image img {
    margin: 0 auto; /* Center image */
    max-width: 90%; /* Ensure image fits on smaller screens */
  }

  .welcome-content h2 {
    font-size: 30px; /* Adjust font size */
  }

  .welcome-content p {
    font-size: 16px; /* Adjust font size */
  }
}

/* Mobile responsiveness (max-width: 768px) */
@media (max-width: 768px) {
  .welcome-section {
    flex-direction: column; /* Stack image and content vertically */
    text-align: center;
  }

  .welcome-content {
    margin: 20px auto; /* Center content */
    text-align: center; /* Center-align text */
  }

  .welcome-content h2 {
    font-size: 28px; /* Reduce font size for mobile */
  }

  .welcome-content p {
    font-size: 16px;
  }

  .features-list {
    font-size: 11.5px;
  }

  .welcome-image img {
    margin: -30px auto;
    max-width: 100%; /* Ensure image fits within screen width */
  }

  .read-more-btn {
    font-size: 16px; /* Adjust button size */
    padding: 10px 20px;
  }
}
